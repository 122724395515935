import { IDevice } from './getDeviceInfo';
import { Brand } from './BrandEnum';
import { getChromeVersion } from './deviceInfoHelpers';

export function getLGDeviceInfo(
  userAgent: string = window.navigator.userAgent
): IDevice | undefined {
  const userAgentLowerCased = userAgent.toLowerCase();
  const regex = /(webos|webostv)([0-9.]+)(\s|;)/;
  const found = userAgentLowerCased.match(regex);

  if (found !== null && found.length >= 3) {
    return {
      brand: Brand.LG,
      version: found[2],
      software: getWebOsSoftwareVersion(userAgent),
      chromeVersion: getChromeVersion(userAgent),
    };
  }

  return undefined;
}

const getWebOsSoftwareVersion = (userAgent: string = window.navigator.userAgent): string => {
  const userAgentData = userAgent.split(';');

  for (let index = 0; index < userAgentData.length; index += 1) {
    const isLastItem = index === userAgentData.length - 1;
    if (isLastItem) {
      break;
    }

    const currentElement = userAgentData[index];

    if (currentElement.toLowerCase().indexOf('webos') > -1) {
      const currentElementSplit = currentElement.split(' ');
      if (currentElementSplit.length > 2) {
        return currentElementSplit[currentElementSplit.length - 1];
      }

      return userAgentData[index + 1].trim();
    }
  }

  return 'unknown';
};

export function isWebOSVersionGreaterThan2(
  userAgent: string = window.navigator.userAgent
): boolean {
  const webosVersion = getLGDeviceInfo(userAgent)?.version;
  if (webosVersion) {
    try {
      return parseFloat(webosVersion) > 2;
    } catch (error) {
      return false;
    }
  }
  return false;
}

export function isWebOSVersionGreaterOrEqualToSuppliedVersion(
  // eslint-disable-next-line default-param-last
  userAgent: string = window.navigator.userAgent,
  suppliedVersion: number
): boolean {
  const webosVersion = getLGDeviceInfo(userAgent)?.version;
  if (webosVersion) {
    try {
      return parseFloat(webosVersion) >= suppliedVersion;
    } catch (error) {
      return false;
    }
  }
  return false;
}
