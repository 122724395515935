import { getLGDeviceInfo } from './getLGVersion';
import { getTeliaDeviceInfo } from './getTeliaVersion';
import { getSamsungDeviceInfo } from './getSamsungVersion';
import { getPanasonicDeviceInfo } from './getPanasonicVersion';
import { getDeviceInfoFromLocalSystem } from './getDeviceInfoFromLocalSystem';
import { Brand } from './BrandEnum';
import { getVestelDeviceInfo } from './getVestelDeviceInfo';
import { getPhilipsDeviceInfo } from './getPhilipsDeviceInfo';

export interface IDevice {
  brand: Brand | string;
  version: string;
  software: string;
  chromeVersion: string;
  model?: string;
}

function getDeviceInfo(userAgent: string = window.navigator.userAgent): IDevice {
  const webOsVersion = getLGDeviceInfo(userAgent);
  const samsungVersion = getSamsungDeviceInfo(userAgent);
  const teliaVersion = getTeliaDeviceInfo(userAgent);
  const panasonicVersion = getPanasonicDeviceInfo(userAgent);
  const hbbtvEmulatorVersion = getHbbtvEmulatorDeviceInfo(userAgent);
  const localSystemDeviceInfo = getDeviceInfoFromLocalSystem();
  const vestelVersion = getVestelDeviceInfo(localSystemDeviceInfo, userAgent);
  const philipsVersion = getPhilipsDeviceInfo(userAgent);

  return (
    hbbtvEmulatorVersion ||
    webOsVersion ||
    samsungVersion ||
    teliaVersion ||
    panasonicVersion ||
    vestelVersion ||
    philipsVersion ||
    localSystemDeviceInfo
  );
}

let deviceInfo: IDevice | undefined;

export const getDeviceInfoTestable = (userAgent: string = window.navigator.userAgent): IDevice => {
  // Need to reset deviceInfo between each unit test run
  deviceInfo = undefined;
  return setupAndGetDeviceInfo(userAgent);
};

export default function setupAndGetDeviceInfo(
  userAgent: string = window.navigator.userAgent
): IDevice {
  if (deviceInfo === undefined) {
    deviceInfo = getDeviceInfo(userAgent);
  }
  return deviceInfo;
}

export const getHbbtvEmulatorDeviceInfo = (
  userAgent: string = window.navigator.userAgent
): IDevice | undefined => {
  const userAgentLowerCased = userAgent.toLowerCase();
  const regex = /(hbbtvemulator)/;
  const found = userAgentLowerCased.match(regex);
  if (found !== null && found.length >= 1) {
    return { brand: Brand.HBBTVEMULATOR, version: '', software: '', chromeVersion: '' };
  }

  return undefined;
};

const inMemoryDeviceInfo = setupAndGetDeviceInfo();
export const isGrundig = inMemoryDeviceInfo.brand === 'Arcelik';
export const isVestel = inMemoryDeviceInfo.model
  ? inMemoryDeviceInfo.model.indexOf('Vestel') > -1
  : false;
