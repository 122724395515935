import { getQueryParams } from '../constants/endpoints';

export enum Environment {
  PROD = 'prod',
  STAGE = 'stage',
  DEV = 'dev',
}

export enum Hostnames {
  PROD = 'hbbtv.play.svt.se',
  STAGE = 'stage.hbbtv.play.svt.se',
  LOCALHOST_IP = '10.240.192.60',
  LOCALHOST_IP_2 = '192.168.0.15',
}

export const getHelixEnvironment = (): Environment => {
  const queryParams = getQueryParams();
  const isStage = process.env.HELIX_ENV === 'stage' || queryParams.stageApi === 'true';
  if (isStage) {
    return Environment.STAGE;
  }

  return process.env.NODE_ENV === 'development' ? Environment.DEV : Environment.PROD;
};

export const getAbiskoEnvironment = (): Environment => {
  const queryParams = getQueryParams();
  // const isStage = process.env.AB_ENV === 'stage' || queryParams.stageApi === 'true';

  // TODO: disregard abisko stage env for now to move forward.
  // If we sent abisko variants that are null to Contento we get validation errors
  const isStage = process.env.AB_ENV === 'stage';
  if (isStage) {
    return Environment.STAGE;
  }

  return Environment.PROD;
};

export const getUnoEnvironment = (): Environment => {
  const queryParams = getQueryParams();
  const isStage = process.env.UNO_ENV === 'stage' || queryParams.stageApi === 'true';
  if (isStage) {
    return Environment.STAGE;
  }

  return Environment.PROD;
};

export const getHostnameBadgeName = (hostname = window.location.hostname): string | undefined => {
  if (
    hostname === 'localhost' ||
    hostname === Hostnames.LOCALHOST_IP ||
    hostname === Hostnames.LOCALHOST_IP_2
  ) {
    return Environment.DEV;
  }
  if (hostname === Hostnames.STAGE) {
    return Environment.STAGE;
  }
  return undefined;
};

type SentryEnvironment = 'production' | 'staging' | 'development';

export function getSentryEnvironment(): SentryEnvironment {
  switch (window.location.hostname) {
    case Hostnames.PROD:
      return 'production';
    case Hostnames.STAGE:
      return 'staging';
    default:
      return 'development';
  }
}

const getVideoAPIEnvironment = () => {
  const queryParams = getQueryParams();

  const isStage =
    (process.env.USE_STAGE_VIDEO_API !== undefined && process.env.USE_STAGE_VIDEO_API === 'true') ||
    queryParams.stageApi === 'true';

  return isStage ? 'stage' : 'prod';
};

export const videoAPIEnv = getVideoAPIEnvironment();
